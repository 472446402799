<template>
  <div>
    <!-- 公共头部 -->
    <Header @onChange="change"></Header>
    <div class="container" :style="{ paddingTop: ht + 'px' }">
      <router-view></router-view>
    </div>
    <!-- 公共底部 -->
    <Footer></Footer>
    <!-- 右侧固定 -->
    <RightSide v-show="leftSide"></RightSide>
  </div>
</template>
<script>
import Header from "@/components/base/header.vue";
import Footer from "@/components/base/footer.vue";
import RightSide from "@/components/base/rightSide.vue";
export default {
  components: {
    Header,
    Footer,
    RightSide,
  },
  data() {
    return {
      flag: "",
      ht: 0,
      leftSide: false,

     
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    change(e) {
      if (e) {
        this.ht = 0;
      } else {
        this.ht = 0;
      }
    },
    handleScroll() {
      let scroll =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (scroll >= 500) {
        this.leftSide = true;
      } else {
        this.leftSide = false;
      }
    },
  },
};
</script>
<style lang="less" scoped>
.container {
  min-width: 1200px;
  min-height: calc(100vh - 430px);
  // margin-top: 120px;
  padding-top: 130px;
  box-sizing: border-box;
  // margin-bottom: 140px;
  background-color: #f2f2f2;
}
// .top {
//   margin-top: 120px;
// }
</style>